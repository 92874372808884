import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/nursing-director-retire/NEST360.jpeg';
import blogd2 from '../../assets/images/2022/nursing-director-retire/2.jpeg';
import blogd3 from '../../assets/images/2022/nursing-director-retire/03.jpeg';
import blogd4 from '../../assets/images/2022/nursing-director-retire/02.jpg';
import blogd5 from '../../assets/images/2022/nursing-director-retire/05.jpeg';
import blogd6 from '../../assets/images/2022/nursing-director-retire/04.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  The NEST360; (FCC) viz Bill and Melinda Gates, and (CIFF)
                  courtesy visit to the Chief Medical Director of LUTH
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd1} alt='' />
                  <p
                    className='blog-one__text'
                    style={{ marginBottom: 30, color: 'white' }}
                  >
                    <span></span> <br />
                    The NEST360 ( Newborn Essential Solutions and Technologies)
                    team from USA, UK, Malawi, Kenya, Tanzania, Ethiopia and
                    Nigeria; and the members of the NEST 360 Funders
                    Coordinating Committee (FCC) viz Bill and Melinda Gates, the
                    ELMA Philanthropies and Children’s Investment Fund
                    Foundation(CIFF) paid a courtesy visit to the Chief Medical
                    Director of LUTH , Prof Chris Bode during the site visit on
                    Tues 8th November 2022.
                  </p>
                </div>
                <br />
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-3'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-3'>
                    <img src={blogd4} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Nurse Ndidi Enuwa Retires
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  NEST360 is an international alliance supporting governments to
                  end preventable newborn deaths in African hospitals via
                  distribution of affordable newborn devices, trainings for
                  clinicians and biomedical engineers and utilizing quality data
                  for action. At LUTH that is one of the pilot sites in Nigeria,
                  NEST360 donated radiant warmers, phototherapy machines, bubble
                  CPAP respiratory support , suction machines, oxygen
                  concentrators, glucometers, oxygen splitters, pulse oximeters
                  for quality neonatal care. Furthermore, NEST 360 equipped a
                  technical skills lab and a clinical skills lab for preservice
                  and in service trainings of clinicians, nurses and biomeds.
                  The team was led by Prof Chinyere Ezeaka, Consultant
                  Paediatrician, Neonatology Unit CMUL/LUTH who is the national
                  Clinical Lead, NEST360 Program, Nigeria.
                </p>
                <div className='row'></div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
